<template>
  <div class="nav-center after">
    <template v-if="DEF_webStyle === 'sgph'">
      <b-button class="license" @click="showImage = true" >
        License
      </b-button>
      <div class="licensebox" v-if="showImage">
        <div class="license-img">
          <img :src="require(`@/website/${DEF_webStyle}/images/license-1.jpg`)" />
          <button @click="closeImage" class="license-X" >X</button>
        </div>
      </div>
    </template>
    <b-button v-if="!SeamlessWallet" variant="popup" class="wallet-turning" @click="toggleModal" ref="btnToggle" v-b-modal.modal-tall>
        <i class="icon icon-turning"></i>
        <span>{{ $t("store.transfer.points_records") }}</span>
    </b-button>
    <b-button v-if="ShowConversion" variant="popup" class="wallet-gift" @click="ModalTransfer" ref="btnToggle" v-b-modal.modal-tall>
      <i class="icon icon-turning"></i>
      <span>{{ $t("store.transfer.donate_point_member") }}</span>
    </b-button>
    <template v-for="(box, index) in after">
      <nuxt-link v-if="box.show" :to="box.url" :class="box.class">
        <i :class="box.icon"></i>
        <span>{{ box.title }}</span>
      </nuxt-link>
    </template>
    <nuxt-link to="/system/mail" class="btn wallet-mail">
      <i class="icon icon-mail"></i>
      <span v-if="UnReadCount > 0" class="badge badge-pill badge-danger">{{ UnReadCount }}</span>
      <span>{{ $t("store.web_mail") }}</span>
    </nuxt-link>
    <template v-if="DEF_webStyle === 'jdf'">
      <audioPlay />
    </template>
  </div>
</template>
<script>
const audioPlay = require(`~/components/${process.env.DEF_audioplay}.vue`).default;
import { mapState, mapGetters } from "vuex";
export default {
  name: "modNavCtrA",
  components:{
    audioPlay
  },
  data(){
    return {
      after: [],
      showImage: false,
    }
  },
  methods: {
    closeImage() {
      this.showImage = false;
    },
    ModalTransfer() {
      this.$root.$emit("bv::toggle::modal", "modalTransferPoint", "#btnToggle");
    },
    toggleModal() {
      this.$root.$emit("bv::toggle::modal", "modalExchPoint", "#btnToggle");
    },
    handleAfter() {
      return [
        {
          url: "/wallet/bank",
          title: this.$t("store.wallet.digital_wallet"),
          icon: "icon icon-wallet",
          show: true,
          class: "wallet-bank"
        },
        {
          url: "/wallet/treasure",
          title: this.$t("store.withdrawal.wallet_treasure"),
          icon: "icon icon-diamond",
          show: true,
          class: "wallet-treasure"
        },
        {
          url: "/wallet/deposit",
          title: this.$t("store.desopit.desopit_now"),
          icon: "icon icon-deposit",
          show: true,
          class: "wallet-deposit"
        },
        {
          url: "/share/share",
          title: this.$t("store.share"),
          icon: "icon icon-moneybag",
          show: this.ShowShare,
          class: "wallet-share"
        }
      ]
    }
  },
  mounted() {
    this.after = this.handleAfter();
  },
  computed:{
    ShowShare() {
      return this.WebSetting.SHOW_SHARE === "1";
    },
    ShowConversion() {
      return this.WebSetting.SHOW_CONVERSION === "1";
    },
    DEF_webStyle() {
      return process.env.DEF_webStyle || 'default';
    },
    ...mapState(['WebSetting']),
    ...mapGetters('webSetting', ['SeamlessWallet']),
    ...mapState("webSetting", ['UnReadCount'])
  }
};
</script>
