<template>
  <div
    class="idxKnowhow"
    :data-title="$t('store.customer.contact_us')"
    :data-subtitle="$t('store.customer.h24_service')"
  >
    <div class="know-group" v-for="(item, index) in idxContact" :key="index">
      <img
        :src="require(`@/assets/all/images/banner/desktop/${item.id}.webp?v=1102`)"
        :alt="item.title"
      />
      <div class="title">{{ item.title }}</div>
      <p>{{ item.p }}</p>
      <a :href="item.to" class="btn btn-more">{{ item.btn }}</a>
    </div>
  </div>
</template>
<script>
  import { mapState } from "vuex";
  import { getJsonFile } from '~/utils/jsonFile';
  export default {
    data() {
      return {
        idxContact: null,
      };
    },
    created: function () {
      if (Object.keys(this.$route.query).indexOf('x') >= 0)
        this.idxContact = getJsonFile(process.env.DEF_webStyle, 'idxContact_x');
      if (this.idxContact == null || this.idxContact.length == 0)
        this.idxContact = getJsonFile(process.env.DEF_webStyle, 'idxContact');
    },
  };
</script>
