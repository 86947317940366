<template>
    <b-dropdown id="dropdown-left" variant="link" class="nav-right">
      <template v-slot:button-content>
        <ul>
          <li v-if="ShowLevel && GroupName">
            <i class="icon icon-rank"></i>
            <q>{{ $auth.$state.user.group_name }}</q>
          </li>
          <li>
            <i class="icon icon-user"></i>
            <q>{{ $t("store.user.account") }}: <span>{{ UserName }}</span></q>
          </li>
          <li>
            <i class="icon icon-coin"></i>
            <q>{{ $t("store.user.coin") }}:
              <b-spinner variant="variant" type="grow" small v-if="AccountWalletMainLoading" label="Spinning"></b-spinner>
              <span v-else>{{ AccountWalletMain | currencyInt }}</span>
              <i class="icon icon-refresh" @click="RefreshAccountWallet"></i>
            </q>
          </li>
        </ul>
      </template>
      <b-dropdown-item to="/user/profile">{{ $t("store.user.profile") }}</b-dropdown-item>
      <b-dropdown-item to="/system/mail">{{ $t("store.web_mail") }}</b-dropdown-item>
      <b-dropdown-item href="#" @click="OnLogout">{{ $t("store.btn.logout") }}</b-dropdown-item>
    </b-dropdown>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "modNavRtA",
  data(){
    return {
        AccountWalletMain: 0,
        AccountWalletMainLoading: false,
    }
  },
  created() {
    if (Object.keys(this.AccountWallet).indexOf("0") >= 0)
      this.OnAccountWalletChanged();
    if (Object.keys(this.AccountWalletLoading).indexOf("0") >= 0)
      this.OnAccountWalletLoadingChanged();

    this.$watch(() => this.AccountWallet[0], this.OnAccountWalletChanged);
    this.$watch(
      () => this.AccountWalletLoading[0],
      this.OnAccountWalletLoadingChanged
    );
  },
  methods: {
    OnLogout() {
      this.$auth.logout("local");
    },
    RefreshAccountWallet() {
      this.GetAccountWallet(0);
    },
    OnAccountWalletChanged() {
      this.AccountWalletMain = this.AccountWallet[0];
    },
    OnAccountWalletLoadingChanged() {
      this.AccountWalletMainLoading = this.AccountWalletLoading[0];
    },
      ...mapActions("account", ["GetAccountWallet"]),
  },
  watch: {
    "$auth.$state.loggedIn"(loggedIn) {
    },
    "$auth.$state.user"(user) {
    },
  },
  computed:{
    GroupName() {
      if (this.$auth.$state.user.group_name && this.$auth.$state.user.group_name != "")
        return this.$auth.$state.user.group_name;
      else
        return false;
    },
    ShowLevel() {
      return this.WebSetting.SHOW_LEVEL === "1";
    },
    UserName() {
      if (this.WebSetting.MasterAccount === "account")
        return this.$auth.$state.user.account;
      else
        return this.$auth.$state.user.mobile || this.$auth.$state.user.account;
    },
      ...mapState(['WebSetting']),
      ...mapState("account", ["AccountWallet", "AccountWalletLoading"]),
  }
};
</script>