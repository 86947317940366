<template>
  <div :class="['idex-wrap', 'desktop', FestivalClass, LocaleClass]" ref="wrapper">
    <button id="sound_btn" class="ignore ignore2" @click="sound_btn"></button>
    <pageHead device="desktop" page="layouts"/>
    <autoRefresh />
    <div class="btn-flexall">
      <!-- <div class="flex-left">
        <userModBtn v-for="(item, index) in flexLeft" v-model="flexLeft[index]" :key="index" v-if="item.type" />
      </div>
      <div class="flex-right">
        <userModBtn v-for="(item, index) in flexRight" v-model="flexRight[index]" :key="index" v-if="item.type" />
      </div> -->
      <userModBtn2 :value="floatBtn" />
      <!-- <btnFlexService /> -->
    </div>
    <Header />
    <idxMarquee />
    <slot name="content">
      <pageHead device="desktop" page="index"/>
      <div class="comGame Hotpro">
        <idxSlider />
        <idxComJackpot />
        <idxContact />
        <div class="game_main">
          <idxGame />
          <idxDownload />
        </div>
      </div>
    </slot>
    <Footer />
    <Falling />
    <dialogMarquee />
    <dialogMaintain />
    <dialogExchPoint v-if="ShowTransferPoint" />
    <dialogTransferPoint v-if="ShowExchPoint" />
    <overlay v-model="IsLoading"></overlay>
    <upAgentCode />
    <audio id="beep" src="/audio/btnClick.mp3" type="audio/mp3" muted></audio>
    <fingerprintJS/>
    <slot name="other"/>
  </div>
</template>
<script>
  const Header = require(`~/components/desktop/${process.env.pc_header}.vue`).default;
  const idxMarquee = require(`~/components/desktop/${process.env.pc_marquee}.vue`).default;
  const Falling = require(`~/components/desktop/${process.env.pc_falling}.vue`).default;
  const idxDownload = require(`~/components/desktop/${process.env.pc_idxDownload}.vue`).default;
  let flexLeft = [];
  try { flexLeft = require("$json/flexLeft.json"); } catch (e) {}
  let flexRight = [];
  try { flexRight = require("$json/flexRight.json"); } catch (e) {}
  let floatBtn = [];
  try { floatBtn = require("$json/floatBtn.json"); } catch (e) {}
  import pageHead from "~/components/common/pageHead.vue";
  import autoRefresh from "~/components/common/autoRefresh.vue";
  import userModBtn from "~/components/desktop/userModBtn.vue";
  import userModBtn2 from "~/components/desktop/userModBtn2.vue";
  import btnFlexService from "~/components/desktop/btnFlexService.vue";
  import idxSlider from "~/components/desktop/idxSlider2.vue";
  import idxComJackpot from "~/components/desktop/idxComJackpot.vue";
  import idxContact from "~/components/desktop/idxContact.vue";
  import idxGame from "~/components/desktop/idxGame04.vue";
  import Footer from "~/components/desktop/idxFooter01.vue";
  import dialogMarquee from "~/components/desktop/dialogMarquee.vue";
  import dialogMaintain from "~/components/desktop/dialogMaintain.vue";
  import dialogExchPoint from "~/components/desktop/dialogExchPoint.vue";
  import dialogTransferPoint from "~/components/desktop/dialogTransferPoint.vue";
  import overlay from "@/components/common/overlay.vue";
  import upAgentCode from "@/components/common/upAgentCode.vue";
  import fingerprintJS from "@/components/common/fingerprintJS.vue";
  import { getJsonFile } from '~/utils/jsonFile';
  import { mapState, mapActions, mapGetters } from "vuex";
  export default {
    components: {
      pageHead,
      autoRefresh,
      btnFlexService,
      Header,
      userModBtn,
      userModBtn2,
      idxMarquee,
      idxSlider,
      idxComJackpot,
      idxContact,
      idxGame,
      idxDownload,
      Footer,
      dialogMaintain,
      dialogMarquee,
      dialogExchPoint,
      dialogTransferPoint,
      Falling,
      overlay,
      upAgentCode,
      fingerprintJS
    },
    data(){
      return{
        flexLeft,
        flexRight,
        floatBtn,
        LocaleClass: 'lang-'
      }
    },
    created() {
      if (this.floatBtn == null  || this.floatBtn.length == 0)
        this.floatBtn = getJsonFile(process.env.DEF_webStyle, 'floatBtn');
    },
    mounted() {
      this.$nextTick(function () {
        const sound_btn = document.getElementById('sound_btn');
        if (sound_btn) {
          sound_btn.click();
        }
      });
      this.$nextTick(function () {
        this.LocaleClass = 'lang-' + this.$i18n.locale;
      });
      // if(this.DEF_webStyle === 'jdf'){
      //   const btnLinks = document.querySelectorAll("button, a, .desktop-mar, ul.navbar, .close_selfbox-s");
      //   const audio = document.querySelector("#beep");
      //   const context = new AudioContext();
      //   btnLinks.forEach((btnLink) => {
      //     btnLink.addEventListener("click", () => {
      //       if (context.state === 'suspended') {
      //         context.resume().then(() => {});
      //       }
      //       setTimeout(function() {
      //         audio.muted = false;
      //         audio.play();
      //       }, 200);
      //     });
      //   });
      // }
    },
    methods: {
      sound_btn(){
        if(this.DEF_webStyle === 'jdf'){
          const btnLinks = document.querySelectorAll("button, a, .tab-group, .subm_group, .desktop-mar, ul.navbar, select, input, .close_selfbox-s, .overflow-auto, .custom-checkbox, .sound_on");
          const audio = document.querySelector("#beep");
          const context = new AudioContext();
          function isIOS() {
            return /iPhone|iPad|iPod/i.test(navigator.userAgent);
          };
          btnLinks.forEach((btnLink) => {
            if (btnLink.classList.contains("ignore")) {
              return;
            };
            btnLink.removeEventListener("click", () => {
              if(this.IsAudio == true){
                if (context.state === 'suspended') {
                  context.resume().then(() => {});
                };
                if (isIOS()) {
                  setTimeout(function() {
                    audio.muted = false;
                    audio.play();
                  }, 200);
                } else {
                  audio.muted = false;
                  audio.play();
                };
              };
            });
            btnLink.addEventListener("click", () => {
              if(this.IsAudio == true){
                if (context.state === 'suspended') {
                  context.resume().then(() => {});
                };
                if (isIOS()) {
                  setTimeout(function() {
                    audio.muted = false;
                    audio.play();
                  }, 200);
                } else {
                  audio.muted = false;
                  audio.play();
                };
              };
            });
          });
        };
      },
      scrollGoTop(){
        window.scrollTo({ top: 0 });
      }
    },
    watch: {
      "$route.path": function(path) {
        if(path !== '/')
          this.scrollGoTop();
      },
      "$i18n.locale": function(test) {
        this.LocaleClass = 'lang-' + this.$i18n.locale;
      }
    },
    computed: {
      DEF_webStyle() {
        return process.env.DEF_webStyle || 'default';
      },
      ShowTransferPoint() {
        return this.$auth.$state.loggedIn === true;
      },
      ShowExchPoint() {
        return this.$auth.$state.loggedIn === true && this.WebSetting.SHOW_CONVERSION === "1";
      },
      ...mapState(['WebSetting', 'IsAudio', 'IsLoading']),
      ...mapGetters('webSetting', ['FestivalClass'])
    }
  };
</script>
<style scoped>
  header{
    width: 100%;
  }
</style>
