<template>
  <!-- 跑馬燈 -->
  <div class="header-top">
    <div class="desktop-mar">
      <div
        class="marquee"
        @click="toggleModal"
        ref="btnToggle"
        v-b-modal.modal-center
      >
        <div class="icon icon-new"></div>
        <div class="mq-cont">
          <p :style="`animation-duration: ${seconds}s; -webkit-animation-duration: ${seconds}s;`">
            {{ AnnouncementStr }}
          </p>
        </div>
      </div>
      <template v-for="(list, index) in headerTop">
        <a
          v-if="list.type == 'a'"
          target="_blank"
          :href="list.url"
          class="btn btn-headertop"
        >
          <i :class="list.icon"></i>
          <div class="top-text" :data-title="list.title">
            <span>{{ list.title }}</span>
            <q>{{ list.subtitle }}</q>
          </div>
        </a>
        <nuxt-link v-if="list.type == 'b'" :to="list.url" class="btn btn-headertop">
          <i :class="list.icon"></i>
          <div class="top-text" :data-title="list.title">
            <span>{{ list.title }}</span>
            <q>{{ list.subtitle }}</q>
          </div>
        </nuxt-link>
        <b-button v-if="list.type == 'c'" :to="list.url" class="btn btn-headertop" @click="btnToggleModal(list.needLogin)">
          <i :class="list.icon"></i>
          <div class="top-text" :data-title="list.title">
            <span>{{ list.title }}</span>
            <q>{{ list.subtitle }}</q>
          </div>
        </b-button>
        
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";


var headerTop = [];
try { headerTop = require("$json/headerTop.json"); } catch (e) {}


export default {
  name: "idxMarquee",
  data() {
    return {
      headerTop,
      seconds: 20,
    };
  },
  methods: {
    btnToggleModal(status){
      if (status && this.$auth.$state.loggedIn === false)
        this.$router.push({ name: 'account-login', params: null, query: null });
    },
    toggleModal() {
      this.$root.$emit("bv::toggle::modal", "modalInfo", "#btnToggle");
    },
    getData() {
      this._getAnnouncement().then(() => { this.setSeconds(); });
    },
    setSeconds() {
      if (!this.Announcement || this.Announcement.length == 0)
        return;
      
      let num = this.Announcement.map((item) => item.content).join("&nbsp;&nbsp;&nbsp;&nbsp;").replace(/\s*/g,"").length;
      let s   = parseInt(num / 200) + 1;
      this.seconds = s * 30;
    },
    ...mapActions(["_getAnnouncement"]),
  },
  mounted: function () {
    this.getData();
  },
  watch: {
    Announcement() {
      this.setSeconds();
    },
  },
  computed: {
  	AnnouncementStr() {
      if (!this.Announcement || this.Announcement.length == 0)
        return '';

      return this.Announcement.map((item) => item.content).join("            ");
    },
    ...mapState(["Announcement"]),
  },
};
</script>
