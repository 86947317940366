<template>
  <div class="btn_flex_service">
    <a class="btn btn-online" :href="WebSetting.LIVECHAT" target="_blank">
      <transition name="heartBeat" appear>
        <div class="online-hello">
          <div class="dn dot_wrap">
            <div class="dn dot1"></div>
            <div class="dn dot2"></div>
            <div class="dn dot3"></div>
          </div>
          <p>{{ $t("store.customer.online_hi") }}</p>
          <p v-if="HasLineLink">{{ $t("store.customer.online_howru") }} <b class="lind_id">{{ `${WebSetting.LINEID}` }}</b></p>
        </div>
      </transition>
      <div class="online-img"></div>
      <div class="online-btn">
        <i class="icon icon-smile"></i>
        <div class="online-text">
          <span>{{ $t("store.customer.service_tw") }}</span>
          <span>{{ $t("store.customer.service_en") }}</span>
        </div>
      </div>
    </a>
    <!--<div class="livechat" id="livechat">
      <a href="#" class="chat-close" @click="closeModal"></a>
      <iframe class="chat-frame" :src="WebSetting.LIVECHAT"></iframe>
    </div>-->
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
    };
  },
  methods: {
    toggleModal() {
      document.getElementById("livechat").classList.toggle("show");
      //this.$root.$emit("bv::toggle::modal", "modal-1", "#btnToggle");
    },
    closeModal() {
      document.getElementById("livechat").classList.add("add");
      document.getElementById("livechat").classList.remove("show");
    },
  },
  mounted() {
  },
  watch: {
  },
  computed: {
    HasLivechat(){
        return Object.keys(this.WebSetting).indexOf('LIVECHAT') && this.WebSetting.LIVECHAT;
      },
    HasLineLink(){
        return Object.keys(this.WebSetting).indexOf('LINELINK') && this.WebSetting.LINEID;
      },
    ...mapState(["WebSetting", "hostName"]),
  },
};
</script>