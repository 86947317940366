<template>
  <div class="idxgame hotpro-column idxGame04">
    <h1>{{ title }}</h1>
    <h2>{{ this.$t("store.casino_introduce") }}</h2>

    <div
      class="hotpro-item"
      v-for="(item, index) in SeoList"
      :key="index"
    >
      <h3 class="idxgame-title">{{ item.title }}</h3>
      <p class="idxgame-img">
        <nuxt-link :class="item.gameclass" :to="item.url" :title="item.title">
          <img v-if="item.image" :src="item.image" :alt="item.title" class="img-fluid">
          <img v-else-if="item.style && item.images && !item.imgtag" :src="require(`@/website/${item.style}/images/btn/${item.images}.webp`)" :alt="item.title" class="img-fluid">
          <img v-else-if="item.style && item.images" :src="require(`@/website/${item.style}/images/btn/${item.images}.jpg`)" :alt="item.title" class="img-fluid">
        </nuxt-link>
      </p>
      <div class="idxgame-description" v-html="item.description"></div>
    </div>    
  </div>
</template>
<script>
  import { mapState, mapActions, mapGetters } from "vuex";
  import { getJsonFile } from '~/utils/jsonFile';
  export default {
    data() {
      return {
        seo: null
      };
    },
    created() {
      if (Object.keys(this.$route.query).indexOf('x') >= 0)
        this.seo = getJsonFile(process.env.DEF_webStyle, 'seo_x');
    },
    computed: {
      title(){
        return process.env.WEB_TITLE;
      },
      DEF_webStyle() {
        return process.env.DEF_webStyle || 'default';
      },
      SeoList() {
        return this.seo !== null ? this.seo : this.Seo;
      },
      ...mapState(["WebSetting", 'hostName']),
      ...mapGetters(['GameMeun', 'Seo']),
    }
  };
</script>