<template>
  <li>
    <template v-if="value.type == 'a'">
      <a v-if="value.blank" :href="value.url" target="_blank">
        <span class="line"></span>
        <i :class="value.class"></i>
        <div class="navbar-title">{{ $t(value.title) }}</div>
        <div class="navbar-subtxt">{{ value.subtxt }}</div></div>
      </a>
      <nuxt-link v-else :to="value.url">
        <span class="line"></span>
        <i :class="value.class"></i>
        <div class="navbar-title">{{ $t(value.title) }}</div>
        <span class="navbar-subtxt">{{ value.subtxt }}</span>
      </nuxt-link>
    </template>
    <template v-if="value.type =='b'">
      <a v-if="value.blank" :href="value.url" target="_blank">
        <span class="line"></span>
        <i class="icon icon-newspaper"></i>
        <div class="navbar-title">{{ $t('store.blog') }}</div>
        <span class="navbar-subtxt">BLOG</span>
      </a>
    </template>
    <template v-if="value.type =='c'">
        <a v-show="value.show" :href="M_ExLink8899" target="_blank" rel="nofollow noopener noreferrer">
          <span class="line"></span>
          <i class="icon icon-just8899"></i>
          <div class="navbar-title">{{ $t('store.just8899') }}</div>
          <div class="navbar-subtxt"></div>
        </a>
    </template>
    <template v-if="value.type =='d'">
      <a v-if="value.blank" :href="value.url" target="_blank">
          <span class="line"></span>
          <i class="icon icon-book"></i>
          <div class="navbar-title">Gambling Tips</div>
          <div class="navbar-subtxt"></div>
        </a>
    </template>
  </li>
</template>
<script>
export default {
  name: "modNavLink",
  props: {
    value: Object,
  },
  computed:{
    M_ExLink8899(){
      return process.env.M_ExLink8899;
    },
  }
};
</script>