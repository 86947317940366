<template>
  <b-modal
    id="modalVerify"
    ref="ModalVerifyRef"
    :modal-class="myclass"
    hide-footer
    hide-header
  >
    <div class="modal-header">
      <div class="circle"><i class="icon icon-user"></i></div>
      <h5 class="title">{{ $t("store.btn.user_verify") }}</h5>
    </div>

    <div class="modal-main">
      <b-form @submit.prevent="onSubmitLogin">
        <div class="form-group">
          <label for="account">
            {{ $t("store.user.phone_number") }}
          </label>
          <input
            type="text"
            v-model="form.mobile"
            class="form-control"
            required
          />
          <small id="emailHelp" class="form-text text-muted">
            <i class="icon icon-infomation"></i>
            {{ $t("store.user.registered_mobile") }}
          </small>
        </div>

        <button type="submit" class="btn btn-submit">
          {{ $t("store.btn.ok") }}
        </button>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
  import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
  export default {
    props: {
      value: { type: Object, default () { return {} } },
    },
    data() {
      return {
        myclass: ["dialog-log dialog-log-quiz"],
        form: {
          mobile: ""
        },
      };
    },
    methods: {
      onSubmitLogin(e) {
        e.preventDefault();
        this.$emit('memberLogin', { mobile: this.form.mobile, ...this.value});
        this.$root.$emit("bv::toggle::modal", "modalVerify", "#btnToggle");
      }
    }
  };
</script>
