<template>
  <div class="nav-center before">
    <b-form inline @submit="onSubmitLogin">
      <b-form-group id="input-group-1" label-for="input-1">
        <b-form-input id="input-1" class="nav-username" v-model="form.account" required :placeholder="WebSetting.MasterAccount == 'mobile' ? $t('store.user.phone_number') : $t('store.user.user_id')"></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-2" label-for="input-2">
        <b-form-input type="password" id="input-2" class="nav-userpw" v-model="form.password" required :placeholder="$t('store.pw.user_pw')" autocomplete="off"></b-form-input>
        <nuxt-link class="btn group-forgot btn-secondary" to="/account/forgot">
          <i class="icon icon-question"></i>
        </nuxt-link>
      </b-form-group>
      <b-form-group v-if="ShowLoginCaptcha" id="input-group-3" label-for="input-3" class="group-code">
        <b-form-input type="text" id="input-3" class="nav-captcha" v-model="form.captchas" required :placeholder="$t('store.btn.CAPTCHA')"></b-form-input>
        <div class="input-group-prepend">
          <img :src="CaptchasImg" @click="_changeCaptchas" />
        </div>
      </b-form-group>
      <b-button type="submit" variant="login">{{ $t("store.btn.login") }}</b-button>
    </b-form>
    <dialogVerify v-model="form" @memberLogin="MemberLogin"/>
  </div>
</template>
<script>
  import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
  import dialogVerify from "~/components/desktop/dialogVerify.vue";
  export default {
    name: "modNavCtrB",
    components: {
      dialogVerify
    },
    data() {
      return {
        isSubmit: false,
        form: {
          password: "",
          account: "",
          captchas: "",
          checked: [],
        },
      }
    },
    mounted() {
      this.isSubmit = false;
      this._changeCaptchas();
    },
    methods: {
      onSubmitLogin(e) {
        e.preventDefault();

        if (this.isSubmit)
          return;

        this.isSubmit = true;

        if (this.ImportCheckMobile) {
          this.CheckImportAccount({ account: this.form.account }).then((res) => {
            if (!res)
              this.MemberLogin(this.form);
            else {
              this.isSubmit = false;
              this.$root.$emit("bv::toggle::modal", "modalVerify", "#btnToggle");
            }
          });
        } else {
          this.MemberLogin(this.form);
        }
      },
      MemberLogin(data) {
        let Path = this.$route.name;

        if (this.AccountLineCode)
          data.bind_line = this.AccountLineCode;

        this.$auth.loginWith("local", { data }).then((res) => {
          this._changeCaptchas();

          if (res.status != "ok") {
            this._showMsgBox({ title: this.$t("store.tip"), msg: res.msg });
            this.isSubmit = false;
          } else {
            if (this.AccountLineCode)
              this._setLineCode(null);

            this.GetAccountData().then((res) => {
              if (Path == 'account-login' || Path == 'account-signup')
                this.$router.replace({ path: "/" });
            });
          }
        });
      },
      ...mapActions(["_showMsgBox", "_changeCaptchas"]),
      ...mapActions("account", ["GetAccountData", "CheckImportAccount"]),
      ...mapMutations('login', ['_setLineCode']),
    },
    computed:{
      ...mapState(['CaptchasImg', 'WebSetting']),
      ...mapState('login', ['AccountLineCode']),
      ...mapGetters('webSetting', ['ImportCheckMobile', 'ShowLoginCaptcha'])
    }
  };
</script>