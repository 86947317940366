<template>
  <header>
    <!-- 登入後 -->
    <template v-if="$auth.$state.loggedIn === true">
        <div class="nav-header after">
          <headerTop />
          <div class="header-wrap">
            <nuxt-link to="/" class="logo"></nuxt-link>
            <ul class="navbar">
              <modNavLink v-for="(item, index) in base" v-model="base[index]" :key="index" v-if="item.type" />
            </ul>
            <modNavCtrA />
            <modNavRtA />
          </div>
        </div>
    </template>
    <!-- 登入前 -->
    <template v-if="$auth.$state.loggedIn !== true">
        <div class="nav-header before">
          <headerTop />
          <div class="header-wrap">
            <nuxt-link to="/" class="logo"></nuxt-link>
            <ul class="navbar">
              <modNavLink v-for="(item, index) in base" v-model="base[index]" :key="index" v-if="item.type" />
              <template v-if="DEF_webStyle === 'jdf'">
                <audioPlay />
              </template>
            </ul>
            <modNavRtB />
          </div>
        </div>
    </template>
  </header>
</template>
<script>
const audioPlay = require(`~/components/${process.env.DEF_audioplay}.vue`).default;
  import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
  import headerTop from "~/components/desktop/header/headerTop.vue";
  import modNavRtA from "~/components/desktop/header/modNavRtA.vue";
  import modNavCtrA from "~/components/desktop/header/modNavCtrA.vue";
  import modNavCtrB from "~/components/desktop/header/modNavCtrB.vue";
  import modNavRtB from "~/components/desktop/header/modNavRtB.vue";
  import modNavLink from "~/components/desktop/header/modNavLink.vue";
  export default {
    components:{
      headerTop,
      modNavRtA,
      modNavCtrA,
      modNavCtrB,
      modNavRtB,
      modNavLink,
      audioPlay
    },
    data(){
      return{
        base: []
      }
    },
    mounted() {
      this.init();
      this.changeLang();
    },
    methods: {
      init() {
        if (this.ErrorMsg != "") {
          this._showMsgBox({ title: "", msg: this.ErrorMsg }).then((res) => {
            this._setErrorMsg("");
          });
        }
      },
      baseData(){
        return this.Meun.map(function(value){
          return{
            type: 'a',
            blank: value.blank,
            class: value.class,
            subtxt: value.subtxt,
            title: value.title,
            url: value.url
          }
        })
      },
      changeLang(){
        this.base = this.baseData();
      },
      ...mapActions(["_showMsgBox"]),
      ...mapMutations(["_setErrorMsg"]),
    },
    computed: {
      DEF_webStyle() {
        return process.env.DEF_webStyle || 'default';
      },
      ...mapState(['WebSetting', 'ErrorMsg']),
      ...mapGetters(["Meun"]),
    },
    watch: {
      "$i18n.locale"(language) {
        this.changeLang();
        },
      'ErrorMsg' (ErrorMsg) {
        if (ErrorMsg && ErrorMsg != '') {
          this._showMsgBox({ title: "", msg: ErrorMsg }).then((res) => {
            this._setErrorMsg("");
          });
        }
      },
      UpAgentCode (Value) {
        this.SetLocalStorage(Value);
      }
    }
  }
</script>
